export default {
  title: "Sklad a inventura",
  message: `Zde si můžete prohlédnout stavy všech produktů ve strukturovaném seznamu, upravovat jejich množství a další parametry.
  Zároveň zde můžete provádět inventůru Vašich skladových zásob v porovnání se stavem ve Vašem eshopu.`,
  new_inventory_check: "Provést inventůru",
  cancel_inventory_check: "Zrušit inventůru",
  stock_tab: "Sklad",
  inventory_tab: "Inventůry",
  no_inventory_checks: "Žádné inventůry nenalezeny",
  conducted_inventory_checks: "Provedené inventůry",
  evaluate_inventory_check: "Vyhodnotit inventůru",
  surplus_total: "Přebytek celkem",
  shortage_total: "Manko celkem",
  total_difference: "Rozdíl celkem",
  search_label: "Prohledejte varianty produktů",
  inventory_check_from: "Inventura ze dne:",
  ideal_quantity_explanation: "Pokud stav produktů klesne pod tuto hodnotu, budeme Vás o tom informovat emailem.",
  search_message: "Zadejte jakoukoliv hodnotu nacházející se ve variantě produktu. Jednotlivé hodnoty oddělte čárkou.",

  stock_table: {
    name: "Název",
    sku: "SKU",
    price: "Cena",
    minimum_ideal_quantity: "Ideální stav",
    actual_stock_quantity: "Reálný stav skladu",
    inventory_quantity: "Stav v eshopu",
    value_difference: "Rozdíl v hodnotě",
    quantity_difference: "Rozdíl ve stavu",
  }
}
