export default {
  title: "Konfigurace Shopify účtu",
  message: `Chcete-li připojit svůj stávající účet Shopify a používat jeho produkty a údaje o objednávkách,
  prosím vyplňte níže uvedené informace. Po připojení budete moci spravovat Produkty, Objednávky a další části prostřednictvím
  Ulsemo administrace.`,
  button: "Používat Shopify data a produkty",
  how_to: `Pokud potřebujete pomoc s vyplněním všech níže uvedených informací, podívejte se na naše video o tom, jak
  získat všechny potřebné informace pro připojení vašeho účtu Shopify.`,
  show_video: "Ukázat video-návod",
  hide_video: "Schovat video-návod",
  retest_connection: "Znovu zkusit napojení",
  incorrect_credentials: `Nelze navázat spojení s Vaším obchodem Shopify, zkontrolujte prosím že všechna pole
  jsou vyplněny správně.`,
  incorrect_scopes: `Spojení s vaším obchodem Shopify je nyní navázáno, je však nutné správně nakonfigurovat
  POVOLENÍ ADMIN API na stránce „private app“ Vašeho obchodu Shopify, jak je ukázáno ve video-návodu.`,

  values: {
    should_use_shopify_data: {
      title: "Používat Shopify produkty",
      message: "Budete používat produkty Shopify ve vašem obchodě místo Ulsemo produktů."
    },
    shop_name: {
      title: "Název obchodu",
      message: "Název Vašeho Shopify obchodu."
    },
    shopify_store_url: {
      title: "URL Vašeho Shopify obchodu",
      message: ""
    },
    storefront_access_token: {
      title: "Storefront access token",
      message: "Token který najdete v nastavení Vašeho Shopify obchodu."
    },
    API_key: {
      title: "API klíč",
      message: "API klíč najdete v nastavení Vašeho Shopify obchodu."
    },
    API_password: {
      title: "API heslo",
      message: "API heslo najdete v nastavení Vašeho Shopify obchodu."
    },
    shopify_email: {
      title: "Shopify email",
      message: "Email kterým se přihlašujete do administrace Vašeho Shopify obchodu."
    },
    shopify_password: {
      title: "Shopify heslo",
      message: "Heslo kterým se přihlašujete do administrace Vašeho Shopify obchodu."
    },
  }
}
