export default {
  title: "Oznámení",
  clear_all: "Vymazat vše",

  orders: {
    new_order: "Máte novou objednávku, můžete si ji prohlédnout"
  },

  cross_products: {
    received_payout: "Obdrželi jste PayPal payout z přeprodeje produktů!",
    refund_payout: "Vrácení pěněz za PayPal payout z přeprodeje produktů",
    deleted: "Tento produkt byl z Vašeho obchodu odebrán z důvodu odstranění majitelem",
    updated: "Tento produkt byl aktualizován jejich majitelem",
    paypal_payouts_disabled: "Vámi připojený PayPal účet nemá umožněnou funkci 'Payouts', nebudete proto moci umožnit přeprodej svých produktů dokud ho znovu neumožníte."
  }
}
