export default {
  title: "Bannery sekcí",
  section_title: "Bannery",
  category: "Kategorie",
  sub_category: "Podkategorie",
  description: "Popisek",
  collection_banner: "Banner sekce",
  button_text: "Text tlačítka",
  modal_title: "Přidat banner na  základě  produktu či kolekce",
  upload_banner: "Nahrát obrázek banneru",
  direct_route: "Přímá cesta",
  label: "Žádné bannery sekcí",
  title_input: "Titulek",
  message: `Bannery sekcí Vám umožní ukázat náhled nové kolekce nebo produktu a zároveň sdílet novinky či informace s Vašimi návštěvníky.`,
  default_message: "Zde bude popis Vašeho banneru.",
  default_title: "Zde bude titulek Vašeho banneru.",
  demo_message: "Zde se zobrazí Vaše 'Bannery sekcí'."
}
