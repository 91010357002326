export default {
  title: "Slevové kupóny",
  message: `Nabídka slev může být účinou marketingovou strategii pro Váš Ulsemo obchod.
  Chcete-li nabízet slevy, můžete vytvořit slevové kódy nebo nastavit slevněné ceny u jednotlivých produktů.</br>
  Upozorňujeme ovšem, že pouze slevové kupóny omezené množstvím nebo částkou v košíku budou akceptovány na tržišti Ulsemo.`,
  code_title: "Slevový kupón",
  code_message: "Slevový kupón Vám umožní dát například Vašim věrným zákazníkům něco navíc.",
  automatic_title: "Automatické slevy",
  automatic_message: "Automatické slevy jsou slevy, které se aplikují automaticky, bez nutnosti zadávání slevového kupónu.",
  selection_title: "Vyberte produkty nebo kolekce",
  activity_state: "Stav",
  number_of_uses: "Použito",
  active_from: "Aktivní od",
  discount_code: "slevový kupón",
  discount_code_error_name: "název slevového kupónu",

  codes: {
    code_input_message: "Vaši zákazníci budou tento kupón zadávat v pokladně.",
    code_placeholder: "např. JARNISLEVA",
    types: {
      title: "Typ slevy",
      percentage: "Procentuální",
      fixed: "Pevná částka"
    },
    value: {
      title: "Hodnota",
      applies_to: "Aplikovatelná na",
      all: "Všechny produkty",
      specific_product: "Specifické produkty",
      specific_collection: "Specifické kolekce",
      discount_value: "Hodnota slevy",
    },
    requirements: {
      title: "Minimální požadavky",
      none: "Žádné",
      minimum_amount: "Minimální částka nákupu",
      minimum_items: "Minimální množství zboží v košíku"
    },
    limits: {
      title: "Omezení použití",
      total_usage: "Omezte, kolikrát lze tuto slevu využít celkem"
    },
    dates: {
      title: "Kdy bude aktivní",
      start: "Datum a čas začátku",
      end: "Datum a čas konce",
      end_title: "Nastavit datum a čas konce"
    }
  },
  automatic: {
    automatic_input_message: "Customers will enter this discount code at checkout."
  },
}
