export default {
  project_access_denied: "K tomuto obchodu nemáte přístup.",
  wrong_password: "Spatné heslo. Pokud jste své heslo zapomněly, můžete si jej obnovit kliknutím na 'Zapomenuté heslo'.",
  unknown_project: "Snažíte se přihlásit do neznámého obchodu.",
  invalid_permissions: "Na tento úkon nemáte dostatečné oprávnění.",
  session_expired: "Vaše relace vypršela, zkuste se znovu přihlásit.",
  email_not_verified: `Vaše emailová adresa ještě nebyla verifikována. Běžte prosím do své emailové schránky,
  najděte a otevřete Ulsemo verifikační email a klikněte na odkaz pro verifikaci.`,
  project_limit_reached: "Dosáhly jste maximálního počtu online obchodů.",
  TOO_SHORT: "Telefonní číslo je příliš krátké.",
  "auth/invalid-email": "Emailová adresa má špatný formát.",
  domain_not_unique: "Tato doména je již používaná jiným obchodem, prosím použijte jinou nebo nás kontaktujte.",
  payouts_not_enabled: "Paypal 'Payouts' nejsou povoleny nebo se změny ještě neprojevily, nebude tak možné používat funkci přeprodeje v rámci Ulsemo ekosystému."
}
