export default {
  name: "Příběhový banner",
  section_title: "Vytvořit příběhový banner",
  change_banner: "Změnit banner",
  message: `Příběhový banner Vám umožňí vyprávět příběh o vaší značce, produktech a filozofii.
  Dále můžete přidat tlačítka odkazů na produkty nebo kolekce, takže po dokončení čtení příběhu
  uživatelé mohou jít prozkoumávat Vaší nabídku.`,
  label: "Žádný příběhový banner není vytvořen.",
  delete_banner: "Smazat banner",
  select_links: "Přidat odkaz",
  add_link_to_entity: "Přidat odkaz na nadřazenou kolekci, kolekci nebo přímo produkt.",
  buttons: "Odkazy",
  images: "Hlavní obrázek banneru",
  text: "Text",
  title: "Titulek",
  demo_message: "Zde zobrazíme Váš 'Příběhový banner'."
}
