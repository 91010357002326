export default {
  title: "Nastavení dopravy a adresy obchodu",
  message_title: "Popis",
  message: `V tomto modulu můžete nakonfigurovat, zda chcete použít vlastní způsoby dopravy nebo výchozí metodu,
  který používá speciální software (stále ve vývoji) k vyhledání a výpočtu nejlepších sazeb pro vaše zákazníky.
  Dále můžete nastavit slevy za poštovné závyslé na celkové ceně košíku, nastavit adresu a informace o Vašem obchodu včetně bankovního účtu.`,
  shopify_message: `Pokud si přejete nastavit nové služby Shopify Carrier Services (možnosti dopravy produktů Shopify),
  musíte to udělat prostřednictvím administrace Shopify.`,
  remove_language_message: "Smaže hodnoty překladu ze všech {type}.",
  allowed_countries: "Země do kterých dovážíte",
  allowed_countries_for_method: "Země do kterých dovážíte touto metodou",
  maximum_allowed_shipping_discount: "Maximální sleva na poštovné",
  no_selected_country_message: "Pokud neoznačíte žádné země, budou moci Vaši zákazníci vybrat jakoukoliv zemi.",
  other_shipping_methods: "Další způsoby",
  zasilkovna_helper: `Aby jste získaly níže vedené API parametry, vytvořte si nejprve účet na
  <a href="https://client.packeta.com/{language}/registration/choose-type">Zásilkovně</a> a poté přejděte do sekce
  <a href="https://client.packeta.com/{language}/support/">Klientská podpora</a>.`,

  inputs: {
    title: "Titulek",
    price: "Cena",
    discounted_price: "Zlevněná cena",
    code: "Kód",
    minimum_basket_price: "Minimální celková cena košíku",
    discount_percentage: "Procentuální zlevnění",
    countries: "Země",
    maximum_shipping_discount: "Max. sleva z poštovného",
    estimated_days: "Odhadovaný počet dní",
    use_custom_methods: "Použít vlastní způsoby dopravy",
    enables_on_pickup_payment: "Umožňuje platbu na místě",
    show_in_contact_page: "Zobrazit údaje o adrese obchodu na stránce kontaktu.",
    enable_zasilkovna: "Povolit službu Zásilkovna",
    api_key: "Klíč API",
    api_password: "Heslo API"
  },

  tooltips: {
    use_custom_methods: "Vypočítané sazby za dopravu jsou stále ve vývoji, použijte prosím vlastní způsoby dopravy.",
    enables_on_pickup_payment: "Umožní zákazníkům vybrat možnost platby na místě."
  },

  tabs: {
    custom_methods: "Způsoby dopravy",
    shipping_discounts: "Slevy z poštovného",
    settings: "Nastavení",
    address: "Adresa a informace obchodu",
    address_message: "Nastavte adresu svého online obchodu.",
    custom_methods_message: `Nastavením vlastních metod zakážete zákazníkům možnost vypočítat sazby.
    Budou muset použít jednu z Vašich metod.`,
    shipping_discounts_message: `Zde můžete nastavit slevy na dopravu v závislosti na celkové výši ceny košíku.
    Například: Zákazník má v košíku produkty přesahující 1000 Kč, potom mu můžete dát 100% slevu na dopravu.`,
    settings_message: `V tomto modulu můžete nastavit, do kterých zemí chcete povolit dopravu, maximální částku o
    kterou jste ochotni snížit náklady na dopravu pro své zákazníky (například když je sleva na dopravu) a další.`
  }
}
