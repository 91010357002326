export default {
  product_admin: "Produkty a inventura",
  store_layout_admin: "Rozložení a obsah Vašeho obchodu",
  store_config_admin: "Konfigurace Vašeho obchodu",
  menu: "Menu",
  shopify: "Shopify produkty",
  shopify_message: "Produkty vytvořené na Shopify",
  imported: "Produkty",
  imported_message: "Moje produkty",
  finance: "Finance",
  menu_content: "Menu obsah",
  special_message_banner: "Banner s mimořádnou zprávou",
  collection_banner: "Bannery sekcí",
  popular_products: "Oblíbené produkty",
  highlighted_products: "Zvýrazněné produkty",
  story_banner: "Příběhový banner",
  important_message_bar: "Lišta důležitých sdělení",
  footer_content: "Obsah patičky",
  website_configuration: "Konfigurace stránek",
  translations: "Překlady eshopu",
  miscrosite_translations: "Mikrosity",
  style_config: "Konfigurace barev eshopu",
  splash_image_config: "Nastavení obrázku pozadí Mikrosite",
  logo_upload: "Konfigurace loga a brand banneru",
  shopify_config: "Konfigurace napojení Shopify",
  orders: "Objednávky",
  shipping_options_config: "Nastavení dopravy a adresy",
  rest_config: "Ostatní nastavení",
  project_config: "Konfigurace online obchodu",
  payment_gateway_config: "Konfigurace platebních možností",
  about_page: "Konfigurace stránky O nás",
  discounts: "Slevové kupóny",
  social: "Marketing a správa sociálních medií",
  accounts: "Správa účtů",
  posts: "Příspěvky",
  analytics: "Finanční analýza",
  marketing_analytics: "Marketingová analýza",
  advertising: "Reklamy",
  inventory: "Sklad a inventura",
  legal_translations: "Překlady ostatních stránek",
}
