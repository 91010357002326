export default {
  title: "Finanční přehled Vašeho obchodu",
  no_sold_products: "Zatím jste neprodali žádné produkty",
  no_viewed_products: "Nikdo si zatím neprohlédl žádný Váš produkt.",
  graph_titles: {
    sales_in_days: "Celkový prodej za dané období: {value}{currency_sign}",
    sales_by_product: "Nejlépe se prodávající produkty",
    views_by_product: "Produkty s nejvíce zobrazeními",
    avg_order_value: "Vývoj průměrné hodnoty objednávky",
    best_netting_variants: "Nejlépe vydělávající produkty/varianty"
  },
  graph_labels: {
    sales_in_days: "Prodeje v tento den v {currency}",
    net_profit: "Čistý výdělek z tohoto produktu/varianty v {currency}",
    avg_order_value: "Hodnota v {currency}",
    order_value_raising: "Průměrná hodnota objednávek má vzrůstající trajektorii 👍",
    order_value_falling: "Průměrná hodnota objednávek má klesající trajektorii 👎",
  },
  chart_buttons: {
    thirty_days: "30 dní",
    fourteen_days: "14 dní",
    seven_days: "7 dní",
    top_ten_products: "Nejlepších 10",
    top_five_products: "Nejlepších 5",
    store_products: "Zobrazení v eshopu",
    marketplace_products: "Zobrazení na tržišti",
    both_platforms: "Zobrazení celkem"
  }
}
