import products from "./products"
import side_menu from "./side_menu"
import errors from "./errors"
import menu_content from "./menu_content"
import footer_content from "./footer_content"
import collection_banners from "./collection_banners"
import special_message_banner from "./special_message_banner"
import popular_products from "./popular_products"
import highlighted_products from "./highlighted_products"
import story_banner from "./story_banner"
import important_message_bar from "./important_message_bar"
import translations_editor from "./translations_editor"
import edit_page_type_translations from "./edit_page_type_translations"
import style_configurator from "./style_configurator"
import splash_image_configurator from "./splash_image_configurator"
import logo_and_brand_configurator from "./logo_and_brand_configurator"
import shopify_configurator from "./shopify_configurator"
import shipping_options from "./shipping_options"
import rest_configuration from "./rest_configuration"
import project_configuration from "./project_configuration"
import payment_gateway_config from "./payment_gateway_config"
import admin_content from "./admin_content"
import about_page_configuration from "./about_page_configuration"
import api_messages from "./api_messages"
import orders from "./orders"
import user from "./user"
import languages from "./languages"
import address from "./address"
import user_store_selection from "./user_store_selection"
import financials_module from "./financials"
import social_media_accounts from "./social_media_accounts"
import social_media_posts from "./social_media_posts"
import social_media_ads from "./social_media_ads"
import finance_analytics from "./finance_analytics"
import guide from "./guide"
import discounts from "./discounts"
import analytics from "./analytics"
import product_inventory from "./product_inventory"
import store_setup from "./store_setup"
import new_user from "./new_user"
import video_tutorial from "./video_tutorial"
import notifications from "./notifications"
import others from "./others"
import loading_messages from "./loading_messages"
import user_guide from "./user_guide"

export default {
  user_guide,
  video_tutorial,
  notifications,
  new_user,
  store_setup,
  products,
  side_menu,
  errors,
  menu_content,
  footer_content,
  collection_banners,
  special_message_banner,
  popular_products,
  highlighted_products,
  story_banner,
  important_message_bar,
  analytics,
  translations_editor,
  edit_page_type_translations,
  style_configurator,
  splash_image_configurator,
  logo_and_brand_configurator,
  shopify_configurator,
  shipping_options,
  rest_configuration,
  admin_content,
  social_media_accounts,
  about_page_configuration,
  product_inventory,
  project_configuration,
  payment_gateway_config,
  user_store_selection,
  finance_analytics,
  social_media_posts,
  social_media_ads,
  financials_module,
  api_messages,
  languages,
  address,
  user,
  guide,
  orders,
  discounts,
  others,
  loading_messages,
  section_description_title: "Popis",
  unknown: "Neznámá",
  title: "obchod",
  save: "Uložit",
  create: "Vytvořit",
  cancel: "Zrušit",
  close: "Zavřít",
  select: "Označit",
  delete: "Smazat",
  restore: "Obnovit",
  value: "hodnota",
  add: "Přidat",
  remove: "Odstranit",
  edit: "Upravit",
  done: "Hotovo",
  pay: "Zaplatit",
  send: "Odeslat",
  total: "Celkem",
  discard_changes: "Smazat změny",
  handling_fees: "Manipulační poplatky",
  save_changes: "Uložit změny v sekci: <b>{section}</b>",
  upload: "nahrát",
  currency_sign: "Kč",
  name: "Jméno",
  refresh: "Obnovit",
  data: "data",
  route: "Cesta",
  language_active: "Aktivní řeč",
  language_inactive: "Neaktivní řeč. K aktivaci stačí změnit jakoukoliv hodnotu.",
  drag_and_drop_image: "Přetáhněte obrázek nebo sem klikněte a vyberte z Vašeho zařízení.",
  drag_and_drop_video: "Přetáhněte video nebo sem klikněte a vyberte z Vašeho zařízení.",
  clear_image: "Smazat obrázek",
  upload_image: "Přidat obrázek",
  clear_images: "Smazat obrázky",
  upload_images: "Nahrát obrázky",
  button: "tlačítko",
  updated_to: "aktualizováno k",
  connection_checks: "Napojení na další služby",
  connected: "připojeno",
  not_connected: "nepřípojeno",
  financials: "Finance",
  comming_soon_message: "Další data a grafy brzy přidáme!",
  expand: "Zvětšit",
  live_preview: "Živý náhled",
  demo_store_preview: "Demo náhled stránky",
  demo_store_preview_message: "Pouze demo náhled stránky bez funkčnosti.",
  available_languages: "Dostupné řeči",
  available_languages_message: "Překlady Vašeho e-shopu. Další překlady můžete přidat v sekci: 'Překlady eshopu'.",
  no_permissions_message: "K této stránce nemáte přístup.",
  default_payment_success_message: "Platba proběhla úspěšně.",
  enable_language_message: "Řeč musí být nejprve aktivována v sekci 'Překlady eshopu'.",
  missing_language_values: "Pro danou řeč nejsou vyplněny veškerá nutná pole produktu.",
  show_store_preview: "Otevřít náhled",
  hide_store_preview: "Zavřít náhled",
  full_screen_preview: "Náhled celé obrazovky",
  mobile_preview: "Náhled mobilní obrazovky",
  here: "ZDE",
  next: "Dále",
  continue: "Pokračovat",
  hide: "Zavřít",
  show: "Otevřít",
  finish: "Dokončit",
  ulsemo_data: "Ulsemo data",
  shopify_data: "Shopify data",
  select_currency: "Vyberte měnu",
  title_translation: "Název",
  active: "Aktivní",
  expired: "Vypršelý",
  actions: "Možnosti",
  back: "Zpět",
  financial_analytics_link: "Prohlédnout si finanční analýzu eshopu",
  no_products_or_collections: "Zatím jste nevytvořili žádný produkt nebo kolekci.",
  choose_product_or_collection: "Vyberte produkt nebo kolekci",
  preview_store: "Náhled obchodu",
  close_preview_store: "Zavřít náhled obchodu",
  unsaved_change_store_preview: "Náhled obchodu není aktuální. Uložte lokální změny a znovu náhled otevřete.",
  global_admin_specific_module: "Aby jste mohly používat tento modul, přejděte prosím do globální administrace:",
  time: {
    days: "Dny",
    hours: "Hodiny",
    minutes: "Minuty",
    seconds: "Sekundy"
  },
  countdown_clock_title: "Zkušební verze vyprší za",
  countdown_finished_title: "Vaše zkušební verze vypršela!",
  countdown_finished_message: "Dokud nezaplatíte za služby Ulsemo, budou všechny funkce administrace deaktivovány."
}
