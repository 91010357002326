export default {
  title: "Konfigurace stránky 'O nás'",
  top_banner_image: "Obrázek horního banneru",
  bottom_banner_image: "Obrázek spodního banneru",
  enable_about_page: "Má být stránka 'O nás' viditelná?",
  tiles: "Dlaždice",
  banners: "Bannery",
  bottom_paragraphs: "Spodní odstavce",
  paragraph_title: "Titulek",
  delete_tiles: "Smazat dlaždice",
  add_tiles: "Přidat dlaždice",
  top_paragraph: "Horní odstavce",
  texts: "Ostatní texty",
  page_title: "Titulek stránky",
  page_quote: "Krátká citace",
  change_banner: "Změnit obrázek banneru",
  delete_banner: "Smazat banner",
  about_page: "O nás",
  convert_to_image: "Změnit banner na obrázek",
  convert_to_text: "Změnit banner na text",
  enable_language_message: `Abyste aktivovaly tuto řeč, nejprve běžte do sekce Konfigurace stránky ->
  Překlady a aktivujte vybranou řeč kliknutím na její vlajku a změněním jakéhokoliv pole.`
}
