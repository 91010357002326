export default {
  title: "Objednávky",
  unselect_orders: "Zrušit označení objednávek",

  close_order: "Zavřít objednávku",
  cancel_order: "Zrušit objednávku",
  reopen_order: "Znovu otevřít objednávku",
  fulfill_order: "Vyřídit objednávku",
  edit_order: "Upravit objednávku",
  refund_order: "Refundovat objednávku",
  shipping_and_label: "Vytvořit a zaplatit za přepravní štítek",
  update_tracking_id: "Aktualizovat sledovací ID zásilky",
  no_new_orders: "Žádné nové objednávky.",

  close_orders: "Zavřít objednávky",
  cancel_orders: "Zrušit objednávky",
  reopen_orders: "Znovu otevřít objednávky",
  fulfill_orders: "Vyřídit objednávky",
  edit_orders: "Upravit objednávky",
  refund_orders: "Refundovat objednávky nebo označit jako refundované",
  shipping_and_labels: "Vytvořit a zaplatit za přepravní štítky",
  update_tracking_ids: "Aktualizovat sledovací ID zásilek",
  mark_as_paid: "Označit za zaplacenou",

  orders_selected: "označené objednávky",
  order_origin: "Původ objednávky",
  other_options_info: "Informace o dalších možnostech pro objednávky ze Shopify",
  imported_product_orders: "Objednávky za Ulsemo produkty",
  shopify_product_orders: "Objednávky za Shopify produkty",
  something_went_wrong: "Něco se pokazilo při ukládání. Prosím zkuste to znovu.",
  other_options_info_title: "Další možnosti pro objednávky ze Shopify.",
  view_order_details: "Zobrazit detail objednávky",
  tracking_number: "Sledovací ID zásilky",
  other_options_info_message: `Bohužel jsme zatím nebyly schopni implementovat všechny možnosti objadnávek z Shopify. <br/>
  <b>Pro další možnosti objednávek prosím bežte do administrace Vašeho Shopify obchodu</b><br/><br/>Děkujeme za Vaši trpělivost!`,
  can_not_be_fulfilled_is_refunded: `Jedna nebo více objednávek byly již refundovány a proto je není možné vyřídit.
  Můžete je pouze zavřít nebo zrušit.`,
  titleKey: "Titulek",
  variant: "Varianta",
  latest_orders: "Nejnovější objednávky",
  total_orders: "Všechny objednávky",
  orders_since_beginning: "Všechny objednávky od založení obchodu",
  add_note: "Přidejte poznámku",
  remove_note: "Odeberte poznámku",
  notes_title: "Poznámky k objednávce",
  search_label: "Prohledejte objednávky",
  search_message: "Zadejte jakoukoliv hodnotu nacházející se v objednávce nebo produktu. Jednotlivé hodnoty oddělte čárkou.",
  view_to_all: "Zobrazit všechny objednávky",
  file_download: "Stáhnout objednávky",
  only_paypal: "Tuto funkci lze použít pouze na objednávky placené přes Paypal.",
  only_for_ulsemo: "Tuto funkci nelze použít pouze na objednávky placené přes Shopify platební bránu.",
  only_for_shopify: "Tuto funkci lze použít pouze na objednávky placené přes Shopify platební bránu.",
  payment_method: "Forma platby",
  create_zasilkovna_packet: "Vytvořit novou zásilku pro Zásilkovnu",
  selected_orders_not_zasilkovna: "Ne všechny označené objednávky jsou zasílané přes Zásilkovnu.",
  zasilkovna_packet_created: "Zásilka pro Zásilkovnu již byla vytvořena.",

  table: {
    origin: "Původ",
    order_number: "Objednávka č.",
    order_id: "ID objednávky",
    status: "Stav objednávky",
    financial_status: "Stav platby",
    fulfillment_status: "Stav vyřízení",
    subtotal_price: "Mezisoučet",
    total_line_items_price: "Celková cena produktů",
    total_product_discount: "Celková cena slevových kupónu",
    total_discounts: "Celkové slevy",
    total_shipping_discounts: "Celková cena slev dopravy",
    total_price: "Celková cena",
    total_tax: "Celková částka daní",
    total_shipping_price: "Celková cena za dopravu",
    currency: "Měna",
    created_at: "Datum vytvoření",
    contact_email: "Email uživatele",
    products: "Produkty",
    estimated_profit: "Odhadovaný zisk",
    incl_tax: "vč. daně",
    discounts: "Slevové kupóny",
    excl_tax: "bez daně",
    shipping_method: "Způsob dopravy",
    cross_revenue_reduction: "Snížení zisku z přeprodávaných produktech",
    cross_order: "Jedná se o objednávku s přeprodávnanými produkty",

    statuses: {
      cancel: "zrušena",
      cancelled: "zrušena",
      close: "zavřena",
      closed: "zavřena",
      open: "otevřena",
      opened: "otevřena",
      fulfill: "vyřízena",
      fulfilled: "vyřízena",
      ship: "odeslána",
      shiped: "odeslána",
      edit: "upravena",
      edited: "upravena",
      shipped: "odeslána",
      unshipped: "neodeslána",
      restocked: "produkty naskladněny",
      authorized: "autorizována",
      pending: "čekající",
      paid: "zaplacena",
      refunded: "refundována",
      voided: "zrušeno",
      null: "nevyřízena",
      undefined: "neznámý",
      zasilkovna: "Předejte Zásilkovně"
    }
  },

  payment_methods: {
    paypal: "Paypal",
    bank_transfer: "Bankovní převod",
    on_pickup: "Hotově/kartou při převzetí"
  },

  discount: {
    code: "Název kupónu",
    amount: "Částka/Procenta"
  },

  order_detail: {
    title: "Detail objednávky",
    order_not_found: "Objednávka nebyla nalezena",
    info_title: "Info",
    address_title: "Fakturace a přeprava",
    shipping_method: "Způsob dopravy",
    shipping_method_name: "Název",
    destination_country: "Cílová země",
    destination_state: "Cílový stát",
    base_price: "Základní cena",
    final_price: "Výsledná cena",
    is_custom: "Vlastní metoda",
    product_picture: "Obrázek",
    pruduct_name: "Název",
    pruduct_variants: "Varianty",
    quantity: "Množství",
    price: "Cena",
    location: "Pobočka",
    location_id: "ID pobočky"
  },

  other_method_types: {
    zasilkovna: "Zásilkovna"
  },

  order_edit: {
    title: "Upravit detail objednávky",
    billing_address: "Fakturační info",
    shipping_address: "Doručovací adresa",
    tracking_numbers: "Sledovací ID zásilky",
    can_not_edit_fulfilled: "Již vyřízené objednávky nelze upravovat."
  },

  shipping_modal: {
    shipping_modal_title: "Platba za dopravu objednávky",
    shipping_modal_message: `Pro zahájení procesu vyřízení objednávky musíte nejprve zaplatit za poštovné, který si zákazník vybral.`,
    payment_success_message: "Poštovné úspěšně zaplaceno.",
    payment_info_message: "Toto poštovné jste již zaplatil: {rate_ids}",
    paying_for_orders: "Platíte za tyto objednávky",
    no_unpaid: "Žádné nezaplacené poštovné.",
    shipping_paid: "Přepravní štítky byly vytvořeny a staženy."
  },

  order_print: {
    number: "Objednávka č.{order_number}",
    invoice_title: "Faktura",
    supplier: "Dodavatel",
    customer: "Odběratel",
    shipping_address: "Dodací adresa",
    description: "Popis",
    price: "Cena",
    discount: "Sleva",
    vat: "DPH",
    quantity: "Množství",
    product_total: "Celkem",
    shipping_total: "Poštovné celkem",
    subtotal: "Mezisoučet",
    VAT_total: "DPH celkem",
    invoice_total: "Celkem",
    date_of_creation: "Datum vystavení",
    date_of_paymnet: "Datum platby",
    paid: "Zaplaceno",
    businessID: "IČO",
    vatID: "DIČ"
  },

  pagination: {
    multiple: "Zobrazeno od {from} do {to} z {count} celkem",
    some: "Celkem: {count}",
    one: "Pouze jedna objednávka",
    per_page: "Výsledky na stránce"
  }
}
