export default {
  title: "Moje E-shopy",
  project_name_undefined: "Nedefinovaný název obchodu",
  domain_undefined: "Doména obchodu nedefinována",
  create_new_store: "Vytvořit nový online obchod",
  first_store_title: "Váš první Ulsemo e-shop byl úspěšně vytvořen!<div>Začnete s jeho objevováním:</div>",
  missing_email: "Doplňte svou emailovou adresu",
  select_store_languages: "Jazyky obchodu",
  visit_store: "Navštívit můj e-shop",
  visit_admin: "Přejít do administrace",

  messages: {
    project_name_required: "Název obchodu je požadovaný.",
    project_created: "Nový online obchod úspěšně vytvořen.",
    add_email_address: "Zatím jste nevyplnil/a svou emailovou adresu, prosím vyplňte ji, aby jste mohl/a pokračovat.",
    invalid_email: "Špatný formát email (např. muj@email.cz)",
    first_store_message: "Děkujeme, že jste se rozhodli využít služeb Ulsemo. Jsme tu pro Vás pokud budete potřebovat s čímkoliv pomoct.",
    first_store_title: "Váš první online obchod je hned za rohem!"
  },

  settings: {
    title: "Nastavení",
    delete_account: "Smazat můj účet",
    delete_account_acknowledgment: "Beru na vědomí, že tato akce trvale smaže můj účet a jeho napojení na jakýkoliv obchod na Ulsemu.",
    remove_user_tooltip: "Možné pouze v případě, že nejste vlastníkem žádného obchodu. Nejprve musíte smazat všechny obchody, kterých jste vlastníkem."
  },

  new_store: {
    title: "Váš nový obchod byl úspěšně vytvořen!",
    message: `Vaše 30ti denní bezplatná zkušební verze začíná právě teď. <br/><br/>Pro Vaše pohodlí jsme přidali odpočítávací
    hodiny na horní lištu administrace Vašeho obchodu, takže vždy uvidíte zbývající zkušební dobu.<br/><br/>
    Budeme Vás informovat 7 dní a následně ještě 1 den před koncem zkušebního období, abyste měli dostatek času
    na přeplacení služeb Ulsemo a mohly tak pokračovat v budování svého podnikání.`,
    warning: `Po skončení bezplatné zkušební verze bude Váš obchod stále funkční, ale všechny funkce v administraci
    budou pozastaveny, dokud si nepředplatíte služby Ulsemo.`,
    open_store: "Přejít do nového obchodu",
    new_store_domain: {
      beginning: "Váš obchod si můžete prohlédnout a používat zde:",
      end: "než si připojíte vlastní doménu."
    }
  }
}
