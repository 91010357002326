export default {
  title: "Položky v menu",
  add_item: "Přidat položku v menu",
  section_title: "Položky a pod-položky v menu",
  other_settings: "Další nastavení",
  add_sub_item: "Přidat pod-položku",
  menu_item: "položka menu",
  menu_subitem: "pod-položka menu",
  uppercase_option: "Použít velká písmena pro položky v menu",
  modal_title: "Vytvořit novou položku v menu založenou na produktu",
  add_menu_item: "Přidat prázdnou položku v menu",
  add_menu_item_from_entity: "Vytvořit novou položku v menu založenou na produktu či kategorie",
  for_language: "pro nějakou řeč"
}
