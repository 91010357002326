export default {
  name: "Banner s mimořádnou zprávou",
  title: "Bannery s mimořádnou zprávou",
  section_title: "Vytvořit banner s mimořádnou zprávou.",
  change_banner: "Změnit obrázek banneru",
  message: `Bannery s mimořádnou zprávou Vám umožní představit naléhavé nebo důležité zprávy Vašim zákazníkům.
  Jsou umístěny na samém vrcholu hlavní stránky Vašeho obchodu a jsou to první co Vaši návštěvníci uvidí.`,
  label: "Žádný banner s mimořádnou zprávou není vytvořen.",
  delete_banner: "Smazat banner",
  use_laser_graphic: "Použít grafiku laseru pod bannerem.",
  shown_on_single_and_couple: "Ukazovat banner take na Microsite stránkách.",
  demo_message: "Zde bude Váš banner s mimořádnou zprávou.",
  first_add_image: "Nejdříve musíte přidat obrázek banneru výše.",
  edit_banner: "Upravit banner",
  add_banner: "Přidat banner",
  add_banner_title: "Přidat nový banner",
  alt_text_label: "Alt text",
  alt_text: "Popište obsah banner (Alt text)",

  links: {
    title: "Přidat odkaz k banneru",
    about_page: "Odkázat na stránku O nás",
    product_or_collection: "Odkázat na produkt/kolekci",
    external: "Odkázat na externí url",
    external_placeholder: "https://www.google.com",
    remove: "Odebrat odkaz z banneru",
    selected_link: "Odkaz"
  }
}
