export default {
  title: "Vítejte ve Vašem novém e-shopu!",
  message: "Začněme s krátkou 30s prohlídkou Vašeho e-shopu.",
  continue_button: "Začít prohlídku",

  labels: {
    store_config: "Základní nastavení",
    payment_gateway: "Nastavení platební brány",
    store_address: "Nastavení adresy obchodu"
  },

  tour: {
    products_menu_tour: {
      title: "Produkty a inventura",
      content: "Tato sekce Vám umožní přidávat a upravovat produkty a kolekce."
    },
    store_layout_menu_tour: {
      title: "Vzhled obchodu",
      content: "Tato sekce Vám umožní upravovat vzhled Vašeho obchodu, jako například menu, bannery, patičku, stránku o nás, a další."
    },
    store_config_menu_tour: {
      title: "Nastavení obchodu",
      content: `Tato sekce Vám umožní nastavit základní nastavení obchodu, jazyky, logo, měnu, možnosti dopravy, platební brány, a další.<br>
      Zde můžete také upravit barvy Vašeho obchodu.`
    },
    finance_menu_tour: {
      title: "Finance obchodu",
      content: "Zde můžete zobrazit a spravovat finance Vašeho obchodu, jako například objednávky, slevové kódy, a další."
    },
    marketplace_checkbox_tour: {
      title: "Vystavení produktů na tržišti Ulsemo",
      content: `Při vytváření produktů nezapomeňte zaškrtnout políčku "Umožnit produkt na tržišti Ulsemo".
      Jinak nebudou návštěvníci schopni Váš produkt na našem tržišti dohledat.`
    },
    marketplace_categorization_tour: {
      title: "Kategorizace produktu na tržišti Ulsemo",
      content: `Jako poslední krok, pro vložení produktu na naše tržistě, prosím zařaďte produkt do jedné z možných kategorií.`
    },
    marketplace_collections_tour: {
      title: "Kolekce a Nadřazené kolekce",
      content: `Pokud budete nabízet produkty pouze přes tržište Ulsemo, pak nemusíte tvořit žádné kolekce.
      Produkty na našem tržišti jsou řazeny dle kategorie do které je zařadíte.`
    },
    store_preview: {
      title: "Náhled obchodu",
      content: `Aby jste si vyzkoušely svůj obchod bez nutnosti nastavování domény, klikněte sem a můžete nahlédnout
      do svého obchodu a zkoušet veškeré jeho funkce, vše přímo z administrace.`
    },
    quick_links_menu_tour: {
      title: "Rychlé odkazy",
      content: "Tyto odkazy Vám umožní rychle přejít na Vaše nejčastěji používané sekce v administraci."
    },
  }
};
