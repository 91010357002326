export default {
  title: "Konfigurace platebních možností",
  message: `Máte možnost vybrat, kterou platební bránu chcete použít pro svůj online obchod.`,
  message_paypal_title: "PayPal platební brána",
  message_paypal: `Pokud chcete používat platební bránu PayPal, musíte vyplnit uvedenou PayPal
  konfiguraci.`,
  message_shopify_title: "Shopify platební brána",
  message_shopify: `Pokud chcete používat platební bránu Shopify, musíte nejdříve nakonfigurovat připojení Shopify v
  modulu „Konfigurace připojení Shopify“ v postranní nabídce. Platební bránu Shopify lze dále používat pouze s produkty z
  Vašeho Shopify obchodu, ne s Ulsemo produkty.`,
  paypal_config_title: "PayPal konfigurace",
  use_paypal: "Používat PayPal platební bránu",
  use_shopify: "Používat Shopify platební bránu",
  checking_connection: "Kontrolujeme Vaše napojení na PayPal",
  paypal_not_connected: "PayPal nebyl správně nakonfigurován, prosím zkontrolujte Client ID a Secret ID.",
  shopify_not_connected: "Připojení na Váš Shopify obchod nebylo úspěšně navázáno, proto nemůžete použít Shopify platební bránu.",
  necessary_paypal_config: `Při vytváření aplikace PayPal nezapomeňte v nastavení povolit 'Payouts' a 'Transaction Search',
  bez tohoto nastavení nebude možné používat funkci Ulsemo přeprodeje.`,

  other_payment_options: {
    title: "Další platební možnosti",
    message: "Dejte svým uživatelům na výběr z více způsobů platby a zvyšte tak své konverze.",
    options: {
      bank_transfer: "Bankovním převodem",
      cash_on_pickup: "Hotově/kartou při převzetí"
    },
    bank_transfer_tooltip: `Musíte nejdříve vyplnit bankovní údaje v sekci "Nastavení dopravy a adresy" -> "Adresa a informace obchodu"`
  },

  values: {
    client_id: {
      title: "PayPal 'Client ID'",
      message: "PayPal 'Client ID' poskytované službou PayPal při vytvoření nové aplikace zde:",
      creation_link: "Vytvoření firemního Paypal účtu"
    },
    client_secret: {
      title: "PayPal Secret ID",
      message: "PayPal 'Secret ID' poskytované službou PayPal při vytvoření nové aplikace zde:",
      creation_link: "Vytvoření firemního Paypal účtu"
    },
    email: {
      title: "Paypal account email",
      message: "Tento e-mail se používá k identifikaci Paypal účtu, na který se budou vyplácet veškeré částky ze služby Ulsemo Cross-selling.",
    }
  }
}
