export default {
  title: "Nahrajte loga a banner značky",
  icon: "Nahrát ikonu",
  brand_banner: "Nahrajte banner značky",
  fav_icon: "Nahrajte favicon",
  preview_color: "Náhled na: {color_type}.",
  message: `Vaše logo bude zobrazeno v menu a patičce Vašeho online obchodu, banner značky je zobrazen ve spod každé Mikrosite.
  Banner je použit jako odkaz na domovskou stránku Vašeho online obchodu.
  Dávejte pozor, aby Vaše logo mělo dostatečný kontrast s barvou "tmavé pozadí" a banner značky měl kontrast s barvou "barva stránky".
  Favicon je malá verze Vašeho loga, která bude zobrazena v záložce každého webového prohlížeče.`,
  icon_format_message: "Použijte pouze obrázky v těchto formátech: .png, .jpg/.jpeg, .gif",
  uploading_icon: "Nahrání ikony",
  uploading_banner: "Nahrání banneru",
  uploading_favicon: "Nahrání favicon",
}
