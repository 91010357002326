export default {
  title: "Nová registrace uživatele",
  message: `Vítejte v Ulsemo, jsme velice rádi, že jste se rozhodli vyzkoušet náš produkt.
  Doufáme, že budete se vším spokojeni a v případě jakýchkoliv otázek nás neváhejte kontaktovat.`,
  create_new_user: "Vytvořit účet",
  account_created: "Účet a online obchod vytvořen",

  inputs: {
    first_name: "Křestní jméno",
    last_name: "Příjmení",
    email: "Email",
    email_again: "Email znovu",
    phone: "Telefonní číslo",
    password: "Heslo",
    password_again: "Heslo znovu",
    country: "Země bydliště",
    language: "Preferovaný jazyk",
    project_name: "Jméno obchodu",
    project_domain: "Doména obchodu",
    project_plan: "Typ plánu"
  },

  password_strengths: {
    weak: "slabé",
    normal: "normální",
    strong: "silné"
  },

  messages: {
    emails_unmatched_or_invalid: "Emaily se buď neshodují nebo jsou v chybném formátu.",
    passwords_unmatched: "Hesla se neshodují.",
    password_stength: "Síla hesla",
    user_created_successfully: `Váš účet i onlie obchod byly úspěšně vytvořeny, verifikační email byl zaslán na Vaši emailovou adresu.
    Prosím dokončete registrační proces kliknutím na odkaz ve verifikačním emailu (Může trvat pár minut než email dorazí).`,
    required_field: "* povinné pole",
    not_required_field: "Nepovinné pole"
  }
}
