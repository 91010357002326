export default {
  title: "Konfigurace obrázku pozadí Mikrosite",
  disable_splash: "Nezobrazovat obrázek pozadí.",
  select_product: "Vybrat produkt pro náhled",
  open_product_selector: "Vybrat produkt pro náhled",
  transparent_image_message: "Chcete-li dosáhnout nejlepších vizuálních výsledků, přidejte k tomuto produktu obrázek s průhledným pozadím.",
  message: `Obrázky pozadí se používají jako pozadí pro hlavní obrázky produktů na Mkrosite stránkách.
  Ujistěte se, že obrázky produktu mají dostatečný kontrast s obrázkem pozadí, aby byly snadno viditelný.
  Doporučujeme také dát jako první obrázek označeného produktu, obrázek s pruhledným pozadí ve formátu PNG.`
}
