export default {
  title: "Máte následující chyby:",
  preview_error: "Omlouváme se, ale řešíme nyní softwarovou chybu v náhledu obchodu, budeme se snažit o její co nejrychlejší opravení.",

  collections: {
    collection: "kolekce",
    parent_collection: "Nadřazená kolekce"
  },

  types: {
    missing_name: "Chybí {entity} {value}.",
    selected_items: "Musíte vybrat alespoň jednu {entity}.",
    duplicate_title: "Toto jméno {entity} již existuje, změňte ho prosím."
  },

  product: {
    title: `Produkt "{product_title}" má následující chyby:`,
    duplicate_variants: "Tento produkt má duplicitní varianty, změňte prosím varianty aby měly unikátní hodnoty.",
    missing_price: "{entity} '{entity_name}' chybí cena.",
    missing_variant: "Produktu chybí varianty, prosím přidejte nějaké.",
    missing_dimension: "Jedna nebo více rozměrů chybí, prosím doplňte je.",
    missing_categorization: "Aby jste mohl/a nabízet produkty na Ulsemo marketplace nebo přes Ulsemo Cross-selling, musíte jim nejdříve přiřadit kategorii.",
    missing_country: `Aby jste mohl/a nabízet produkty na Ulsemo marketplace, musíte vyplnit adresu Vašeho online obchodu (adresu na které se Vaše produkty nacházejí),
    v sekci: Konfigurace stránky -> Konfigurace možností dopravy -> Adresa obchodu.`,
    missing_value_for_translation: "Cena a druhy variant musí být vyplněny pro všechny dostupné řeči.",
    missing_purchase_currency: "Váš produkt nebo varianty mají vyplněnou hodnotu 'Vynaložené náklady', ale chybí 'Měna vynaložených nákladů'.",
    paypal_connection_for_cross_selling: `Chcete-li Ulsemo cross-selling, musíte nejprve připojit svůj
    Paypal účet a vyplnit přihlašovací email pro Paypal. Přejít do nastavení platebních bran:`,
    missing_cross_currency: 'Pokud chcete umožnit Ulsemo cross-selling pro tento produkt, musíte vybrat "Konverzní měnu".',
  },

  configuration: {
    empty_shipping_option: "Některé z Vašich možností dopravy nebyly kompletně vyplněny.",
    duplicate_shipping_option: "Máte vícero stejných možností dopravy ({order}).",
    missing_shipping_option_translations: "Musíte vyplnit možnosti dopravy pro všechny dostupné řeči.",
    save_products_warning: `Jelikož jste změnil měnu nebo přidal řeč, byla také měna u produktů změněna. Uložte tuto
    změnu v sekci "Produkty", nebo ji uložte rovnou:`
  },

  finance: {
    discount_entities_not_selected: "Prosím vyberte alespoň jeden produkt/kolekci, nebo zaškrtněte aplikovat na 'Všechny produkty'.",
    missing_limit_value: "Prosím vyplňte hodnotu v sekci 'Omezení použití'.",
    missing_requirements_value: "Prosím vyplňte hodnotu v sekci 'Minimální požadavky'.",
    missing_value: "Prosím vyplňte hodnotu 'Hodnota slevy'.",
    missing_end_date: "Prosím vyberte 'Datum a čas konce'.",
    missing_end_date_before_start: "Datum a čas konce nemůže být před Datum a čas začátku.",
    missing_translation_value: "Prosím vyplňte hodnotu v sekci 'Hodnota slevy' pro každou dostupnou měnu.",
    missing_translation_requirement_value: "Prosím vyplňte hodnotu v sekci 'Minimální požadavky' pro každou dostupnou měnu.",
  },

  social: {
    only_video_or_images: "K příspěvku můžete přidat buďto video nebo obrázky, ale ne obojí.",
    video_max_size_reached: "{provider} požaduje aby video nepřesahovalo velikost {size_limit} a délku {duration_limit}.",
    video_max_size_reached: "Video nesmí přesahovat velikost 1GB a délku 20 minut.",
    empty_post: "Nemůžete sdílet prázdný příspěvek.",
    select_channel: "Vyberte alespoň jeden kanál na kterém chcete příspěvek sdílet.",
    instagram_media_upload_limit: "Na Instagram můžete nahrát pouze jeden obrázek nebo video.",
    image_doesnt_comply: "{provider} požaduje aby obrázek nepřesahovalo velikost {size_limit} a byl v jednom z těchto formátů: {supported_formats}.",
    ig_image_dimensions_dont_comply: "Instagram požaduje aby šířka obrázku byla v rozmezí {width} a poměru stran {ratio}.",
    missing_campaign_name: "Název kampaně musí být vyplněn.",
    missing_campaign_objective: "Cíl kampaně musí být vyplněn.",
    missing_campaign_state: "Stav kampaně musí být vyplněn.",
    missing_bid_cap: "Vybraná FB kampaň vyžaduje limit nabídky.",
    missing_ad_name: "Název reklamy musí být  vyplněn.",
    missing_media: "Musíte nahrát alespoň jeden obrázek nebo video.",
    missing_ad_link: "Odkaz musí být vyplněný.",
    missing_display_link: "Pro každé nahrané médium je nutné vyplnit odkaz.",
    ad_account_not_selected: "Musíte vybrat alespoň jeden reklamní účet.",
    ad_campaign_not_selected: "Musíte vybrat alespoň jednu kampaň.",
    ad_fb_page_not_selected: "Musíte vybrat jaká FB stránka bude k reklamě přidružená.",
    ad_fb_audience_not_selected: "Musíte vybrat alespoň jedno publikum pro každý vybraný reklamní účet."
  },

  new_user: {
    TOO_SHORT: "Neplatné telefonní číslo"
  },

  uploads: {
    general: "Během nahrávání Vašeho obrázku došlo k problému, zkuste prosím přenačíst stránku a nahrát obrázek znovu.",
    file_size_limit_exceeded: `Limit pro obrázky je 10MB, nahrajte prosím obrázek o menší velikosti, nebo zkuste snížit jeho velikost kompresí <a href="https://tinypng.com/" target="_blank">ZDE</a>.`
  }
}
