export default {
  title: "Upravit barvy obchodu",
  change_color: "Změnit barvu",
  select_color: "Vybrat barvu",
  set_to_default: "Nastavte jako výchozí barvu",
  message: `Před publikováním webu nezapomeňte otestovat všechny vlastní barvy.
  Špatné barvy můžou způsobit SEO problémy kvůli nedostatečnému kontrastu mezi textovou barvou a barvou pozadí.
  Doporučujeme nepoužívat příliš živé barvy a zajistit dostatečný kontrast mezi textem a barvami na pozadí.`,
  palettes_title: "Vyzkoušejte naše palety barev",
  palettes_message: "Připravili jsme pro Vás několik moderních palet, které Vám umožní rychleji nastartovat své podnikání a zlepšit branding.",

  style_translations: {
    font_light: {
      title: "Barva světlého textu",
      message: `Barva používaná hlavně u textu, který má jako barvu pozadí „Barvu tmavého pozadí“.`
    },
    font_heavy: {
      title: "Barva tmavého textu",
      message: `Barva používaná hlavně u textu, který má jako barvu pozadí „Barvu světlého pozadí“.
      Dále se používá pro malé aspekty celého webu, jako je barva okrajů, ikony atd.`
    },
    background_light: {
      title: "Barva světlého pozadí",
      message: `Barva použitá například na stránce s podrobnostmi o produktu, na Příběhovém banneru a další.`
    },
    background_heavy: {
      title: "Barva tmavého pozadí",
      message: `Barva použitá pro pozadí hlavních prvků na webu, jako je menu, náhled košíku, patička, ...`
    },
    body_color: {
      title: "Barva pozadí obchodu",
      message: `Barva použitá jako barva pozadí celého webu. Při jeho změně buďte opatrní!`
    },
    item_name_color: {
      title: "Barva textů v menu",
      message: `Barva textu v menu která má jako barvu pozadí „Barvu tmavého pozadí“.`
    }
  }
}
