export default {
  title: "Dodatečná nastavení",
  message: "Zde můžete konfigurovat nastavení SEO své stránky a integraci Google, Facebooku a dalších analytických nástrojů.",
  seo_title: "SEO konfigurace",
  analytics_title: "Konfigurace analytických nástrojů",

  values: {
    seo_description: {
      title: "SEO popisek stránek",
      message: "Popisek využívaný internetovými vyhledávači pro lepší kategorizaci Vašich stránek."
    },
    theme_color: {
      title: "Hlavní barva Vašich stránek v HEX formátu",
      message: "Barva, která je použita jako pozadí pro horní lištu mobilních webových prohlížečů."
    },
    seo_keywords: {
      title: "SEO klíčová slova",
      message: "Klíčová slova použitá pro lepší indexaci internetovými vyhledávači."
    },
    google_site_verification: {
      title: "Google site verifikační kód",
      message: "Kód sloužící k verifikování vlastnictví Vašich stránek pro Google."
    },
    fb_pixel_id: {
      title: "Facebook Pixel ID",
      message: ""
    },
    hotjar_id: {
      title: "Hotjar App ID",
      message: ""
    },
    ga_project_id: {
      title: "Google analytics Measurement nebo Tracking ID",
      message: "Zde vkládejte Tracking or Measurement ID Vašeho projektu v Google analytics."
    },
    fb_site_verification: {
      title: "Facebook site verifikační kód",
      message: "Kód sloužící k verifikování vlastnictví Vaší pro Facebook business manager."
    }
  }
}
