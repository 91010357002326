export default {
  title: "Hlavní konfigurace online obchodu",
  message: "Zde můžete konfigurovat e-maily spojené s vaším webem,a zároveň doménu a e-mailového poskytovatele, ke kterým jsou vaše e-mailové adresy připojeny.",
  domain_error: "Špatný formát domény (např. mujeshop.cz)",
  test_connection: "Poslat test email",
  test_connection_tooltip: "Zašle na {email} testovací email o nové objednávce tak jak bude vypadata pro Váš eshop i zákazníka.",
  test_connection_success: "Testovací email úspěšně poslán, podívejte se do příchozích zpráv emailu: {email}.",
  test_connection_tooltip_disabled: "Ujistěte se, že jste vyplnil/a email pro objednávky i jeho heslo a že všechny změny v konfiguraci obchodu jsou uloženy.",

  values: {
    domain: {
      title: "Doména online obchodu",
      message: "Nastavte doménu Vašeho online obchodu, například: mujeshop.cz. Po zadání nové domény budete kontaktováni s dalšími kroky k připojení domény."
    },
    email_service: {
      title: "Poskytovatel emailu",
      message: `Zde nastavíte svého poskytovatele e-mailových služeb, např. Seznam, Google, Yahoo, .... Pokud nemůžete najít svého
      poskytovatel e-mailových služeb v níže uvedeném seznamu, dejte nám prosím vědět a my se pokusíme přidat jeho konfiguraci na naše servery.`
    },
    info_email: {
      title: "Emailová adresa pro informační účely",
      message: "Emailová adresa na kterou se budou zasílat zprávy od zákazníků z Vašeho online obchodu."
    },
    order_email: {
      title: "Emailová adresa pro objednávky",
      message: `E-mailová adresa, na kterou budeme zasílat informace o příchozích objednávkách. Tato adresa bude také použita k zasílání e-mailů s potvrzením a změnách stavu objednávek
      svým zákazníkům. K tomu budeme potřebovat heslo k tomuto e-mailu, abychom tak mohli učinit vaším jménem.`
    },
    order_email_password: {
      title: "Heslo k emailu pro objednávky",
      message: "Heslo kterým se přihlašujete k emailu který bude využit na informování uživatelů o potvrzení a změnách stavu objednávek."
    },
    project_name: {
      title: "Název online obchodu",
      message: "Název online obchodu."
    }
  },

  messages: {
    Gmail: `
      Vidíme, že používáte Gmail jako svého poskytovatele e-mailu.
      Budete muset buďto povolit přístup pro méně bezpečné aplikace nebo vytvořit speciální heslo pro aplikaci.
      <a href="https://myaccount.google.com/lesssecureapps" target="_blank"><b>Méně bezpečné aplikace</b></a> or 
      <a href="https://security.google.com/settings/security/apppasswords" target="_blank"><b>Speciální heslo pro aplikaci</b></a>
      <br/><br/>
      <a href="https://support.google.com/accounts/answer/185833?hl=en" target="_blank">ZDE</a> naleznete návod jak vytvořit speciální heslo pro aplikaci.
    `,
    Seznam: "Pokud chcete používat Seznam email a máte zapnutou dvoufázové ověření, budete muset toto dvoufázové ověření vypnout k umožnění přihlášení pomocí hesla.",
    Gmail_password: "Pokud používáte Gmail s dvoufázovým ověřením, budete muset použít speciální heslo pro aplikace. Můžete si jej vytvořit <a href='https://security.google.com/settings/security/apppasswords' target='_blank'>ZDE</a>.",
  }
}
