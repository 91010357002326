export default {
  title: "Přehled nad analytickými daty",
  message: `Tento modul Vám umožní mít přehled nad Vašimi návštěvníky a
  dělat tak lépší rozhodnutí, vše na jednom místě.`,
  missing_GA_id: "Nejdříve musíte připojit svůj Google Analytics účet v 'Ostatní nastavení' Vašeho obchodu",
  missing_GA_views: "Nejdříve musíte připojit nějaké výběry dat z Google analytics.",

  titles: {
    new_vs_returning: "Nový vs Vracející se návštěvníci",
    geo_view: "Odkud jsou Vaši návštěvníci",
    sources: "Zdroje uživatelů",
    no_data: "Zatím neexistují žádná data.",
    dates: "Vyberte datum počátku a konce"
  },

  labels: {
    new_visitor: "Nový návštěvník",
    returning_visitor: "Vracející se návštěvník",
    top_countries: "Hlavní země",
    direct: "Přímo",
    new: "Nový",
    returning: "Vracející se",
    top_sources: "Hlavní zdroje",
    source: "Zdroj"
  }
}
