export default {
  title: "Správa připojených účtů na sociálních sítích",
  message: "V tomto modulu můžete spravovat napojení na profily, stránky, skupiny a další účty v rámci vybraných sociálních sítí.",
  connect_page: "Připojit stránky",
  connect_group: "Připojit skupiny",
  select_entities_to_connect: "Vyberte {source} {entity} které chcete připojit",
  entity_list: "Nepřipojené {entity}",
  connected_entities: "Připojené {entity}",
  social_media_platfroms: "Platformy sociálních médií",
  facebook_entities: "Facebook stránky",
  no_entities_found: "Žádné {source} {entity} nebyly nalezeny.",
  no_entities_connected: "Žádné {source} {entity} nejsou připojeny.",
  load_entities: "Načíst {entity}",
  connect: "Připojit",
  disconnect: "Odpojit",
  switch_account: "Přepnout účet",
  logged_in_as: "Přihlášen jako",
  fb_group_connection_message: `<b>Aby jste připojili Vaši Facebook skupinu k Ulsemo, musíte přidat Ulsemo aplikaci do aplikaci připojených k Vaší skupině.
  Pokud nevíte jak na to, prohlédněte si krátký návod <a href="https://youtu.be/WPlJgOaRjyQ?t=42" target="_blank">ZDE</a></b>`,
  connect_google_analytics_view: "Přidat GA ID výběru dat",
  google_analytics_view_help: "GA ID výběru dat naleznete na analytics.google.com -> Správce -> Nastavení výběru dat",
  google_signin: "Přihlásit přes Google",

  providers: {
    fb: "Facebook",
    ig: "Instagram",
    google: "Google"
  },
  entity_types: {
    pages: "stránky",
    groups: "skupiny",
    accounts: "účty",
    ad_accounts: "reklamní účty",
    ad_campaigns: "reklamní kampaně",
    analytics_views: "výběry dat",
  }
}
