export default {
  dismiss: "Zavřít",

  quick_link_tooltips: {
    products: "Produkty - přidávat, upravovat a spravovat produkty",
    orders: "Objednávky - zobrazit a spravovat objednávky",
    about_page: "O Nás - upravit stránku O Nás",
    shipping: "Doprava - nastavit možnosti dopravy",
    popular_products: "Oblíbené produkty - aktualizovat nejoblíbenější produkty",
    style_config: "Nastavení barev - změnit styly Vašeho obchodu",
  },

  quick_link_titles: {
    title: "Rychlé Odkazy",
    products: "Produkty",
    orders: "Objednávky",
    about_page: "O Nás",
    shipping: "Možnosti Doprava",
    popular_products: "Oblíbené Produkty",
    style_config: "Nastavení Barev",
  }
}
