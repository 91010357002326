export default {
  title: "Překlady a lokalizace",
  message: `Při přidávání nového jazyka nezapomeňte také aktualizovat překlady vašich produktů/variant, stránky "O nás", způsoby dopravy a patičku.`,
  translations_key: "Překladový klíč",
  value: "Hodnota",
  deactivate_language: "Deaktivovat řeč",
  activate_language: "Aktivovat řeč",
  global_translations: "Globální překlady",
  activation_message: "Vybraná řeč zatím nebyla aktivována.",
  global_translations_tooltip: "Překlady pro tlačítka, tituly, popisky a další části online obchodu.",
  special_page_translations: "Překlady ostatních stránek",
  special_page_translations_tooltip: "Překlady stránek: Zásady ochrany osobních údajů, Zásady vrácení zboží, Podmínky Vašeho obchodu.",
  remove_language_message: "Musíte mít alespoň jednu řec povolenou.",
  privacy_policy: "OCHRANA OSOBNÍCH ÚDAJŮ",
  return_policy: "VRACENÍ ZBOŽÍ A REKLAMACE",
  terms_and_condition: "OBCHODNÍ PODMÍNKY",
  footer_message: `
  Nezapomeňte nahradit pole označená <b>XXXXXXXX</b> pravými informacemi.<br/>
  Pokud chcete z vašeho webu vyloučit jednu z níže uvedených stránek, nechte její obsah prázdný a
  odeberte odkaz odkazu ze zápatí (nezapomeňte otestovat patičku po její úpravě).<br/>
  Přejděte na stránku úpravy patičky:`,
  footer_page_link: "ZDE",
  currency: "Měna",
  currency_sign: "Znak měny",
  tax_percantage: "Procento DPH",
  tax_percantage_message: "Procento, ze kterého vypočítáme hodnotu daně přičítané ke každému nákupu, a zobrazené v košíků Vašich zákazníků."
}
