export default {
  title: "Správa a tvorba Mikrositů",
  message: `Jedná se o texty (překlady), které se používají na každé Miscrosite produktu či kolekce.
  Když se Miscrosite vytvoří přidáním odkazu na ní do menu, banneru sekce nebo banneru příběhu, vytvoří se nový záznam
  pro tuto stránku a vy ji následně můžete zde upravit.`,
  single_product_title: "Mikrosity pro produkty",
  couple_product_title: "Mikrosity pro kolekce",
  add_type_page: "Přidejte překlad pro {type}",
  couple_product: "Mikrosity kolekcí",
  single_product: "Mikrosity produktů",
  modal_title: "Označte {entity}",

  entity_type: {
    product: "produkt",
    collection: "kolekci"
  }
}
