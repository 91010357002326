export default {
  title: "Správa příspěvků na sociálních sítích",
  message: `Tento modul Vám umožní vytvářet nové příspěvky jak jednotlivě, tak plošně, přes veškeré účty na všech připojených sociálních sítí.
  Dále si můžete prohlédnout posledních 10 příspěvků ze všech připojených kanálů.`,
  click_to_show_posts: "Kliknutím zobrazíte nejnovější příspěvky z vybraného profilu, stránky, skupiny, apod.",
  page_or_group_not_found: `Stránku nebo skupinu, kterou jste vybrali, se nepodařilo najít, zkontrolujte svůj
  Facebook profil a ujistěte se, že máte oprávnění správce.`,
  no_posts_found: "Nebyly nalezeny žádné příspěvky.",
  previewing_posts: "Prohlédnout příspěvky",
  creating_posts: "Vytvořit příspěvky",
  select_social_media: "Vyberte platformu",
  select_social_channel: "Vyberte kanál",
  no_channels_connected: `Nemáte připojeny žádné kanály, běžte do sekce <b>"Správa účtů"</b> a připojte své kanály na sociálních mediích.`,
  textarea_message: "Co chcete sdílet?",
  channels_sharing: "Vyberte kanály na kterých chcete sdílet",
  add_post_images: "Přidat obrázky",
  add_post_video: "Přidat video",
  add_post_emoji: "Přidat emoji",
  publish: "Zveřejnit",
  link_title: "Odkaz v příspěvku",
  create_from_product: "Vytvořit příspěvek z produktu nebo kolekce",
  choose_produkt_or_collection: "Vyberte produkt nebo kolekci",
  remove_image: "Odstranit obrázek",
  remove_video: "Odstranit video",
  upload_video: "Nahrát video",
  upload_image: "Nahrát obrázek",
  post_published_message: "Příspěvky byly úspěšně nahrány na vybrané {provider} kanály, můžete si je prohlédnout v sekci 'Prohlédnout příspěvky'.",

  table: {
    created_time: "Datum vytvoření",
    picture: "Obrázek",
    attachments: "Všechny přílohy",
    text_content: "Textový obsah",
    is_published: "Je publikován",
    date_published: "Datum publikování",
    name: "Název",
    image: "Obrázek"
  }
}
