export default {
  divider: "NEBO",
  select_demo_images: "Vyberte si jeden z našich",
  next: "Další",
  skip: "Přeskočit",
  back: "Zpět",
  skip_guide: "Přeskočit průvodce",
  finish: "Dokončit",
  save: "Uložit",
  search: "Hledat",
  create_store: "Vytvořit můj obchod!",

  step_1: {
    title: "Jdeme na to!<br>Nyní vás provedeme přidáním vašeho prvního produktu.",
    create_product: "Vytvořit nový produkt",
    select_demo_product: "Použít ukázkový produkt",
  },
  step_2: {
    title: "Nejprve přidejte název a popis produktu.",
    product_title: "Název produktu",
    product_description: "Popis produktu",
  },
  step_3: {
    title: "Nyní přidejme nějaké obrázky!",
    add_image: "Přidat obrázek",
  },
  step_4: {
    title: "Teď nastavíme cenu a skladové zásoby produktu.",
    product_price: "Cena produktu",
    product_inventory: "Skladové zásoby produktu",
    price_unit: "Kč",
    inventory_unit: "ks",
  },
  step_5: {
    title: "A nakonec vytvoříme kolekci/kategorii kam zařadíme váš produkt.",
    collection_name: "Název kolekce - např. Trička, Boty, atd.",
    collection_image: "Přidejte také obrázek kolekce",
  },
  step_6: {
    title: "Nyní, když máme produkt, vyberte kde všude ho vaši uživatelé budou moci vidět.",
    add_product_to: "Přidat produkt do",
    menu: "Menu",
    menu_tooltip: "Menu je hlavní navigace vašeho obchodu. Můžete přidat odkazy na produkty, kolekce, stránku o nás a další.",
    popular_products: "Nejoblíbenější položky",
    popular_products_tooltip: "Nejoblíbenější položky jsou zobrazeny na úvodní stránce, takže jsou mezi prvními věcmi, které vaši zákazníci uvidí.",
    collection_banner: "Bannery produktů/kolekcí",
    collection_banner_tooltip: "Tyto bannery slouží k detailnějšímu popisu produktu nebo kolekce.",
    highlighted_products: "Zvýrazněné produkty",
    highlighted_products_tooltip: "Tato sekce slouží ke zvýraznění produktů, na které chcete upozornit, a je umístěna ve spodní části hlavní stránky.",
    add_to_all: "Přidat do všech",
    collection_banner_message: "Tato kolekce vás opravdu překvapí! Mrkněte na ní.",
    product_banner_message: "Tento produkt vás opravdu překvapí! Mrkněte na něj.",
    collection_banner_button: "Mrkněte na naší kolekci",
    product_banner_button: "Mrkněte na náš produkt",
    show_product: "Zobrazit produkt",
  },
  step_7: {
    title: "A nakonec, nastylujme váš obchod! Můžete si vybrat jednu z našich palet nebo si vytvořit vlastní.",
    select_palette: "Vyberte paletu barev",
    create_custom_palette: "Vybrat vlastní barvy",
    add_logo: "Přidat logo",
    select_pre_made_palette: "Vyberte předpřipravenou paletu",
    change_logo: "Změnit logo",
  },
  step_8: {
    title: "Nyní zbývá už jenom pojmenovat váš obchod a vybrat jeho jazyky.",
    store_name: "Název obchodu",
    store_languages: "Jazyky obchodu",
    skip_title: "Přeskočme všechny tedy ty žvásty a vytvořte si svůj obchod, stačí nastavit název a jazyky vašeho obchodu.",
  },

  demo_product: {
    title: "Tričko street style",
    description: "Tričko se zajímavým street style designem, navržené našimi skvělými návrháři. Je vyrobeno z 100% bavlny a velice dobře se hodí na každodenní nošení.",
  },

  demo_collection: {
    title: "Trička",
  },
}
