export default {
  address1: "Ulice",
  address2: "Ulice další",
  city: "Město",
  first_name: "Křestní jméno",
  last_name: "Příjmení",
  zip: "PSČ",
  country: "Země",
  phone: "Telefon",
  state: "Stát",
  company: "Společnost",
  name: "Jméno",
  email: "Email",
  street1: "Ulice",
  street2: "Ulice další",
  businessID: "IČO",
  vatID: "DIČ",
  bank_account: "Bankovní účet",
  iban: "IBAN",
  swift: "BIC (SWIFT)",
  bank_details: "Bankovní detaily Vaše obchodu",
  bank_detail_message: "Vyplňte pouze v případě, že umožňujete platbu převodem.",
  vs_format: "Formát variabilního symbolu na fakturách bude stejný jako číslování objednávek, tedy:"
}
