export default {
  title: "Správa reklam na sociálních sítích",
  message: "V tomto modulu můžete vytvářet, prohlížet a spravovat reklamy na všech připojených reklamních účtech na sociálních sítích.",
  add_ad_images: "Přidat obrázky",
  add_ad_video: "Přidat video",
  add_ad_emoji: "Přidat emoji",
  create_from_product: "Vytvořit reklamu z produktu nebo kolekce",
  headline: "Titulek",
  description: "Popis",
  display_link: "Odkaz",
  ad_button: "Tlačítko výzvy k akci",
  primary_text: "Hlavní text reklamy",
  campaign_errors: "Problémy kampaní",
  ad_account_errors: "Problémy účtů",
  display_link_error_message: "Reklamy na karuselu nemohou obsahovat odkazy",
  apply_now: "Zažádat hned",
  download: "Stáhnout",
  get_quote: "Získat nabídku",
  learn_more: "Chci vědět víc",
  sign_up: "Registrovat se",
  ad_channels_sharing: "Vyberte reklamní účet",
  channels_sharing: "Vyberte kanál přes které reklamu sdílet",
  creating_ads: "Vytvořit reklamu",
  previewing_ads: "Prohlédnout své reklamy",
  ad_objective: "Cíl reklamy",
  ad_status: "Počáteční stav reklamy",
  campaign_objective: "Cíl kampaně",
  campaign_status: "Počáteční stav kampaně",
  campaign_created: "Kampaň úspěšně vytvořena.",
  create_new_campaign: "Vytvořit novou kampaň",
  lifetime_budget: "Celkový rozpočet",
  daily_budget: "Denní rozpočet",
  campaign_name: "Název kampaně",
  fb_currency_message: "V měně Facebook účtu",
  audience_select: "publikum",
  audince_selection_title: "Vyberte publika pro vybrané reklamní kampaně",
  create_ad_title: "Vytvořte novou reklamu",
  start_date: "Počáteční datum",
  end_date: "Konečné datum",
  ad_name: "Název nové reklamy",
  ad_published: "{provider} reklama pod kampaní {name} byla úspěšně vytvořena.",
  ad_billing_event: "Chci platit za",
  no_selection: "Žádné",
  pixel_id: "ID FB pixelu",
  bid_cap: "Limit nabídky FB reklamy",
  promoted_object_event_type: "Propagovaná událost",
  clear_ad: "Smazat reklamu",
  some_campaign_ads_not_creates: `Nekteré z Vašich reklam pro vybrané kampaně/reklamní účty nebyly vytvořeny z důvodu problémů ze strany Facebooku.
  Uložili jsme pro Vás veškerá data reklamy a nechali označené kampaně, u kterých se vyskytly problémy,
  aby jste mohl upravit data a zkusit reklamu znovu publikovat.`,
  facebook_errors: "Problémy ze strany Facebooku",
  no_ads_found: "Žádné reklamy nenalezeny",
  remove_slide: "Smazat",
  add_channels_message: "Nejprve musíte přidat Facebook reklamní účty zde",

  objectives: {
    APP_INSTALLS: "Instalace aplikace",
    BRAND_AWARENESS: "Povědomí o značce",
    CONVERSIONS: "Konverze",
    LINK_CLICKS: "Kliknutí na odkaz",
    LOCAL_AWARENESS: "Místní povědomí",
    MESSAGES: "Zprávy",
    OFFER_CLAIMS: "Offer claims",
    PAGE_LIKES: "Počet lajků na stránce",
    POST_ENGAGEMENT: "Interakce s příspěvkem",
    REACH: "Dosah",
    STORE_VISITS: "Návštěvy na eshopu",
    VIDEO_VIEWS: "Zobrazení videa",
    IMPRESSIONS: "Impressions"
  },
  ad_statuses: {
    ACTIVE: "Aktivní",
    PAUSED: "Pozastaveno"
  },
  ad_buttons: {
    APPLY_NOW: "Podat žádost",
    DOWNLOAD: "Stáhnout",
    GET_QUOTE: "Získat nabídku",
    LEARN_MORE: "Dozvědět se víc",
    SIGN_UP: "Registrujte se",
    LIKE_PAGE: "Lajkněte stránku",
    SHOP_NOW: "Nakupovat hned",
    WATCH_VIDEO: "Prohlédnout si video",
    BUY_NOW: "Koupit teď",
    CONTACT_US: "Kontaktuje nás",
    SEE_MORE: "Vidět víc"
  },
  billing_events: {
    IMPRESSIONS: "Impresi",
    LINK_CLICKS: "Klik na odkaz",
    APP_INSTALLS: "Instalaci aplikace",
    PAGE_LIKES: "Lajk na stránce",
    POST_ENGAGEMENT: "Aktivitu na příspěvku",
    VIDEO_VIEWS: "Shlédnutí videa"
  },
  ad_event_types: {
    ADD_TO_CART: "Přidání do košíku",
    ADD_TO_WISHLIST: "Přidání do oblíbených",
    INITIATED_CHECKOUT: "Přechod k zaplacení",
    ADD_PAYMENT_INFO: "Přidaní platebních údajů",
    PURCHASE: "Nákup",
    SEARCH: "Hledání zboží",
    OTHER: "Jiné"
  }
}
